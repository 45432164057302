(function (w, $) {
  if (!window.sbf) {
    window.sbf = {};
  }

  // example:
  // <div style="margin-bottom: 10px;">
  //                      <div id="sbf-login-form-username-error" class="alert error popover" style="bottom: 56px; display: none;">
  //                      </div>
  //                      <input class="fill" id="sbf-login-form-username" type="text" placeholder="Username" tabindex="1"><br>
  //  </div>

  sbf.form_errors = {
    show_error: function (id, errorhtml, hide_close_button) {
      if (errorhtml) {
        if (!hide_close_button) {
          $('#' + id + '-error').html(errorhtml + '<button class="close" type="button">&times;</button>');
        } else {
          $('#' + id + '-error').html(errorhtml);
        }
      }

      $('#' + id + '-error').fadeIn(200);
      $('#' + id).addClass('error');
      $('#' + id).focus();
    },

    close_error: function (id) {
      $('#' + id).removeClass('error');
      $('#' + id + '-error').fadeOut(200);
    },

    close_all_errors: function (parentdiv) {
      if (!parentdiv) {
        parentdiv = '';
      } else {
        parentdiv = '#' + parentdiv;
      }
      $(parentdiv + ' [id$="-error"]').fadeOut(200);
      $(parentdiv + ' input').removeClass('error');
      $(parentdiv + ' select').removeClass('error');
    },

    // init function
    init: function () {
      // x buttons should close errors
      $('[id$="-error"]').delegate('button.close', 'click', function (click) {
        click.preventDefault();
        $(this).parent().fadeOut(200, function () {
          $(this).parent().find('input').removeClass('error');
          $(this).parent().find('select').removeClass('error');
        });
      });

      // typing in an input field should remove error class
      $('input').keydown(function () {
        $(this).removeClass('error');
        $('#' + $(this).attr('id') + '-error').fadeOut(200);
      });

      $('select').change(function () {
        $(this).removeClass('error');
        $('#' + $(this).attr('id') + '-error').fadeOut(200);
      });
    }
  };

  $(sbf.form_errors.init);
})(window, jQuery);
